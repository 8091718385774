module.exports = {
  // old
  // title: '天音视通——平台管理',
  // baseUrl: 'https://testp.tianyinvod.com', // 测试项目地址
  // baseApi: 'https://testy.tianyinvod.com', // 测试api请求地址 http://192.168.6.24:8787

  // new ytsaas.com
  title: '天音视通——平台管理',
  baseUrl: 'https://testp.tianyinsaas.cn', // 测试项目地址
  // baseApi: 'https://yty.ytsaas.com', // 测试api请求地址
  // baseUrl: 'http://114.55.62.115:8282', // 测试项目地址
  baseApi: 'http://192.168.6.83:8282', // 测试api请求地址




  // 新框架测试
  // title: '天音视通——平台管理',
  // baseUrl: 'http://newapi.tianyinsaas.cn', // 测试项目地址
  // baseApi: 'http://newapi.tianyinsaas.cn', // 测试api请求地址


};
